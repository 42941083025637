// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SavedIcon_wrap__a\\+1XM {
    position: absolute;
    left: 100%;
    top: 0;
}

.SavedIcon_wrap__a\\+1XM .SavedIcon_savedIcon__bYLvc {
    -webkit-user-select: none;
            user-select: none;
    color: var(--accent-color);
    font-weight: var(--bold);
    padding: 14px 21px;
}

.SavedIcon_wrap__a\\+1XM .SavedIcon_savedIconExit__9nmQY {
    opacity: 1;
}

.SavedIcon_wrap__a\\+1XM .SavedIcon_savedIconExitActive__rKbtJ {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}


`, "",{"version":3,"sources":["webpack://./src/shared/ui/SavedIcon/SavedIcon.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,MAAM;AACV;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,0BAA0B;IAC1B,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,0CAA0C;AAC9C","sourcesContent":[".wrap {\n    position: absolute;\n    left: 100%;\n    top: 0;\n}\n\n.wrap .savedIcon {\n    user-select: none;\n    color: var(--accent-color);\n    font-weight: var(--bold);\n    padding: 14px 21px;\n}\n\n.wrap .savedIconExit {\n    opacity: 1;\n}\n\n.wrap .savedIconExitActive {\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 300ms, transform 300ms;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `SavedIcon_wrap__a+1XM`,
	"savedIcon": `SavedIcon_savedIcon__bYLvc`,
	"savedIconExit": `SavedIcon_savedIconExit__9nmQY`,
	"savedIconExitActive": `SavedIcon_savedIconExitActive__rKbtJ`
};
export default ___CSS_LOADER_EXPORT___;
