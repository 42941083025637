// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_wrap__aN-1h{
    width: 100%;
    position: relative;

}

.PasswordInput_wrap__aN-1h input {
    width: 100%;
}

.PasswordInput_eyeButton__rBa6j {
    position: absolute;
    top: 35%;
    right: 5%;
    background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/entities/PasswordInput/PasswordInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,uBAAuB;AAC3B","sourcesContent":[".wrap{\n    width: 100%;\n    position: relative;\n\n}\n\n.wrap input {\n    width: 100%;\n}\n\n.eyeButton {\n    position: absolute;\n    top: 35%;\n    right: 5%;\n    background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `PasswordInput_wrap__aN-1h`,
	"eyeButton": `PasswordInput_eyeButton__rBa6j`
};
export default ___CSS_LOADER_EXPORT___;
