// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_wrap__y2vy3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 75px);
    width: 100%;
    flex-direction: column;
    background: var(--light-primary-color);
}

.NotFound_smile__RTlAD {
    font-size: 96px;
    color: var(--accent-color);
    transform: rotate(90deg) scale(1, -1);
    margin-bottom: 50px;
    font-weight: var(--light);
}

.NotFound_title__D8KzW {
    margin-bottom: 20px;
    text-align: center;
    font-size: var(--text3);
}

.NotFound_subtitle__wVVcj {
    margin-bottom: 60px;
    font-size: var(--text4);
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;IAC1B,WAAW;IACX,sBAAsB;IACtB,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,qCAAqC;IACrC,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".wrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: calc(100vh - 75px);\n    width: 100%;\n    flex-direction: column;\n    background: var(--light-primary-color);\n}\n\n.smile {\n    font-size: 96px;\n    color: var(--accent-color);\n    transform: rotate(90deg) scale(1, -1);\n    margin-bottom: 50px;\n    font-weight: var(--light);\n}\n\n.title {\n    margin-bottom: 20px;\n    text-align: center;\n    font-size: var(--text3);\n}\n\n.subtitle {\n    margin-bottom: 60px;\n    font-size: var(--text4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `NotFound_wrap__y2vy3`,
	"smile": `NotFound_smile__RTlAD`,
	"title": `NotFound_title__D8KzW`,
	"subtitle": `NotFound_subtitle__wVVcj`
};
export default ___CSS_LOADER_EXPORT___;
