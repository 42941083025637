// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrap__6dzT4 {
    display: flex;
    align-items: center;
    padding: 10px 80px;
    background-color: var(--light-primary-color);
    border-bottom: 1px solid var(--dark-primary-color);
    height: 80px;
}

.Header_navigation__yl7c9 {
    margin-right: auto;
}

.Header_wrap__6dzT4 a {
    font-weight: var(--semiBold);
    font-size: 18px;
}

.Header_wrap__6dzT4 ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
}

.Header_wrap__6dzT4 ul li {
    margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,4CAA4C;IAC5C,kDAAkD;IAClD,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".wrap {\n    display: flex;\n    align-items: center;\n    padding: 10px 80px;\n    background-color: var(--light-primary-color);\n    border-bottom: 1px solid var(--dark-primary-color);\n    height: 80px;\n}\n\n.navigation {\n    margin-right: auto;\n}\n\n.wrap a {\n    font-weight: var(--semiBold);\n    font-size: 18px;\n}\n\n.wrap ul {\n    display: flex;\n    list-style: none;\n    justify-content: space-around;\n    align-items: center;\n}\n\n.wrap ul li {\n    margin: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Header_wrap__6dzT4`,
	"navigation": `Header_navigation__yl7c9`
};
export default ___CSS_LOADER_EXPORT___;
