// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomCheckbox_wrap__E\\+GMB{

}

.CustomCheckbox_wrap__E\\+GMB input[type="checkbox"] {
    accent-color: var(--accent-color);
}

.CustomCheckbox_wrap__E\\+GMB label{
    margin-left: 5px;
    font-weight: var(--semiBold);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/CustomCheckbox/CustomCheckbox.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;AAChC","sourcesContent":[".wrap{\n\n}\n\n.wrap input[type=\"checkbox\"] {\n    accent-color: var(--accent-color);\n}\n\n.wrap label{\n    margin-left: 5px;\n    font-weight: var(--semiBold);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CustomCheckbox_wrap__E+GMB`
};
export default ___CSS_LOADER_EXPORT___;
