// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Account_wrap__9oYBB {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding:20px 0 100px;
}

.Account_wrap__9oYBB h1 {
    font-size: var(--text2);
    margin-bottom: 20px;
}


.Account_userIconWrap__XerBk {
    width: 150px;
}

.Account_userProps__7nt69 {
    border-spacing: 10px;
    margin-bottom: 30px;
}

.Account_userProps__7nt69 input{
    width: 100%;
    font-size: 16px;
}
.Account_userProps__7nt69 tr {
    text-align: left;
}

.Account_userProps__7nt69 th, .Account_userProps__7nt69 td {
    padding:10px 10px;
    border:1px solid var(--accent-color);

}
`, "",{"version":3,"sources":["webpack://./src/pages/Account/Account.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,oCAAoC;;AAExC","sourcesContent":[".wrap {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    padding:20px 0 100px;\n}\n\n.wrap h1 {\n    font-size: var(--text2);\n    margin-bottom: 20px;\n}\n\n\n.userIconWrap {\n    width: 150px;\n}\n\n.userProps {\n    border-spacing: 10px;\n    margin-bottom: 30px;\n}\n\n.userProps input{\n    width: 100%;\n    font-size: 16px;\n}\n.userProps tr {\n    text-align: left;\n}\n\n.userProps th, .userProps td {\n    padding:10px 10px;\n    border:1px solid var(--accent-color);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Account_wrap__9oYBB`,
	"userIconWrap": `Account_userIconWrap__XerBk`,
	"userProps": `Account_userProps__7nt69`
};
export default ___CSS_LOADER_EXPORT___;
