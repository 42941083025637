// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_wrap__xLWl\\+ {
    height: calc(100vh);
    width: 100vw;
    display: flex;
}

.Loader_loader__RF7jU {
    width: 100px;
    height: 20px;
    border-radius: 10px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.Loader_loader__RF7jU::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dark-primary-color);
}

.Loader_loader__RF7jU::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    bottom: 0;
    z-index: 2;
    background-color: var(--accent-color);
    animation: Loader_loaderAnimation__6z3dg .875s infinite ease-in-out alternate;
}



@keyframes Loader_loaderAnimation__6z3dg {
    0% {
        left: -100%
    }
    100% {
        left: 100%
    }
}


`, "",{"version":3,"sources":["webpack://./src/shared/ui/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;AAC/C;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,WAAW;IACX,SAAS;IACT,UAAU;IACV,qCAAqC;IACrC,6EAA+D;AACnE;;;;AAIA;IACI;QACI;IACJ;IACA;QACI;IACJ;AACJ","sourcesContent":[".wrap {\n    height: calc(100vh);\n    width: 100vw;\n    display: flex;\n}\n\n.loader {\n    width: 100px;\n    height: 20px;\n    border-radius: 10px;\n    margin: auto;\n    position: relative;\n    overflow: hidden;\n}\n\n.loader::before {\n    content: '';\n    z-index: 1;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: var(--dark-primary-color);\n}\n\n.loader::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: -100%;\n    width: 100%;\n    bottom: 0;\n    z-index: 2;\n    background-color: var(--accent-color);\n    animation: loaderAnimation .875s infinite ease-in-out alternate;\n}\n\n\n\n@keyframes loaderAnimation {\n    0% {\n        left: -100%\n    }\n    100% {\n        left: 100%\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Loader_wrap__xLWl+`,
	"loader": `Loader_loader__RF7jU`,
	"loaderAnimation": `Loader_loaderAnimation__6z3dg`
};
export default ___CSS_LOADER_EXPORT___;
