// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutoSavedTextInput_wrap__VmbON {
    position: relative;
}

.AutoSavedTextInput_wrap__VmbON .AutoSavedTextInput_property__tbKq9 {
    color: #767676;
}`, "",{"version":3,"sources":["webpack://./src/features/AutoSavedTextInput/AutoSavedTextInput.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".wrap {\n    position: relative;\n}\n\n.wrap .property {\n    color: #767676;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `AutoSavedTextInput_wrap__VmbON`,
	"property": `AutoSavedTextInput_property__tbKq9`
};
export default ___CSS_LOADER_EXPORT___;
