// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_wrap__Nh5oU{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
}

.Home_wrap__Nh5oU p{
    width: 360px;
    font-size: var(--text5);
    margin-bottom: 30px;
}
.Home_wrap__Nh5oU h3 {
    font-size: var(--text3);
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".wrap{\n    display: flex;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    padding-top: 50px;\n}\n\n.wrap p{\n    width: 360px;\n    font-size: var(--text5);\n    margin-bottom: 30px;\n}\n.wrap h3 {\n    font-size: var(--text3);\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Home_wrap__Nh5oU`
};
export default ___CSS_LOADER_EXPORT___;
